import { CloseIcon } from '@assets/icons'
import { NewBuySellFlowIllustration } from '@assets/illustrations'
import {
  Button,
  ButtonedTabs,
  Card,
  CustomModal,
  Spacer,
  TabBar,
  Text,
} from '@components'
import { useTranslation } from '@hooks'
import {
  AssetManagerCode,
  AssetType,
  OrderOperationTypeEnum,
  OrderTypeEnum,
  PeriodEnum,
} from '@interfaces'
import { LimitOrderCard } from 'features/moneyOperations/buySellOperation/cards/limitOrderCard/limitOrderCard'
import { StockOverview } from 'features/moneyOperations/buySellOperation/stockOverview'

export const BuySellExplanation = ({
  tryProduct,
  close,
}: {
  tryProduct: () => void
  close: () => void
}): JSX.Element => {
  const { t } = useTranslation()

  const doNothingFn = (): void => {
    // Do nothing
  }

  const stockFakeData = {
    id: 'MMM:NASDAQ',
    companyName: 'Crown Castle International Corp.',
    price: {
      amount: 811.58,
      currency: 'USD',
    },
    lastClosed: {
      amount: 811.58,
      currency: 'USD',
    },
    logoUrl: '',
    symbol: 'MMM',
    marketStatus: { status: 'OPEN', closingTime: '' },
    increasePercentage: 0.2,
    increaseMoney: {
      amount: 887.1,
      currency: 'USD',
    },
    chart: [],
    period: PeriodEnum.day,
    priceTime: '15min delay',
    kiidUrl: '',
    assetManager: {
      code: AssetManagerCode.blk,
      name: 'BlackRock',
      logo: 'https://assets.dev.miservicio.name/savingsPlans/icons/BLK.png',
    },
    type: AssetType.savingPlan,
    market: 'XNYS',
    isEnabled: true,
    isLowLiquidity: false,
  }

  const positionFakeData = {
    id: '',
    ticker: '',
    type: AssetType.etf,
    shares: 31,
    lockedShares: 1,
    currentValue: {
      amount: 200,
      currency: 'USD',
    },
    totalEarnings: {
      amount: 211,
      currency: 'USD',
    },
    totalPerformance: 11,
    portfolioPercentage: 22,
    avgEntryPrice: {
      amount: 145,
      currency: 'USD',
    },
    totalEarningsToday: { amount: 0, currency: 'USD' },
    totalPerformanceToday: 23,
  }

  return (
    <CustomModal
      size='big'
      height='fullHeight'
      show
      placeOn='right'
      dismissable
      onClose={close}
    >
      <CustomModal.Header>
        <Button
          onClick={() => close()}
          buttonType='link'
          autoWidth
          content={<CloseIcon color='iconPrimaryInitial' size={16} />}
          shape='square'
        />
      </CustomModal.Header>
      <CustomModal.Content>
        <Spacer preset='small' />
        <div className='--no-interactive-content d-flex d-flex-col w-100'>
          <div className='d-flex justify-center w-100'>
            <NewBuySellFlowIllustration />
          </div>
          <Spacer preset='medium' />
          <Text
            align='center'
            preset='headline2'
            text={t('buySellFlow.newFlowExplanation.title')}
          />
          <Spacer preset='tiny' />
          <Text
            align='center'
            preset='paragraph2'
            text={t('buySellFlow.newFlowExplanation.subtitle')}
            color='secondary'
          />
          <Spacer preset='small' />
          <Text
            preset='paragraph2'
            text={t('buySellFlow.newFlowExplanation.sections.first.title')}
          />
          <Spacer preset='tiny' />
          <Text
            preset='paragraph2'
            text={t('buySellFlow.newFlowExplanation.sections.first.caption')}
            color='secondary'
          />
          <Spacer preset='smaller' />
          <div className='d-flex justify-center'>
            <ButtonedTabs<OrderOperationTypeEnum>
              tabs={[
                { id: OrderOperationTypeEnum.buy, title: 'Buy' },
                { id: OrderOperationTypeEnum.sell, title: 'Sell' },
              ]}
              activeTab={OrderOperationTypeEnum.buy}
              setActiveTab={doNothingFn}
              loading={false}
            />
          </div>
          <Spacer preset='small' />
          <Text
            preset='paragraph2'
            text={t('buySellFlow.newFlowExplanation.sections.second.title')}
          />
          <Spacer preset='tiny' />
          <Text
            preset='paragraph2'
            text={t('buySellFlow.newFlowExplanation.sections.second.caption')}
            color='secondary'
          />
          <Spacer preset='smaller' />
          <Card bgColor='transparent' padding='none' bordered width='full'>
            <TabBar
              extraMarginOnSides
              tabsStyle='w-100'
              tabs={[
                { id: OrderTypeEnum.market, title: 'Market' },
                { id: OrderTypeEnum.limit, title: 'Limit' },
              ]}
              activeTab={OrderTypeEnum.market}
              setActiveTab={doNothingFn}
              loading={false}
            />
          </Card>
          <Spacer preset='small' />
          <Text
            preset='paragraph2'
            text={t('buySellFlow.newFlowExplanation.sections.third.title')}
          />
          <Spacer preset='tiny' />
          <Text
            preset='paragraph2'
            text={t('buySellFlow.newFlowExplanation.sections.third.caption')}
            color='secondary'
          />
          <Spacer preset='smaller' />
          <Card bgColor='transparent' padding='smaller' bordered>
            <StockOverview
              companyStocks={stockFakeData}
              companyPosition={positionFakeData}
              openSharesInfoModal={doNothingFn}
            />
          </Card>
          <Spacer preset='small' />
          <Text
            preset='paragraph2'
            text={t('buySellFlow.newFlowExplanation.sections.forth.title')}
          />
          <Spacer preset='tiny' />
          <Text
            preset='paragraph2'
            text={t('buySellFlow.newFlowExplanation.sections.forth.caption')}
            color='secondary'
          />
          <Spacer preset='small' />
          <LimitOrderCard
            focusOnInit={false}
            type={OrderOperationTypeEnum.buy}
            showLimitBuyInfoModal={doNothingFn}
            showLimitSellInfoModal={doNothingFn}
            openCalendar={doNothingFn}
            showAvailableInfoModal={doNothingFn}
            showTimeInForceModal={doNothingFn}
            disabled
          />
          <Spacer preset='small' />
        </div>
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button
          onClick={() => tryProduct()}
          text={t('buySellFlow.newFlowExplanation.cta')}
        />
      </CustomModal.Footer>
    </CustomModal>
  )
}
