import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Button, SummaryCardProps, SummaryRowProps } from '@components'
import {
  BuySellTransaction,
  DepositWithdrawalTransaction,
  DividendTransaction,
  Money,
  OrderCancellationReason,
  OrderTransactionChildStatusEnum,
  OrderTransactionStatusEnum,
  OrderTypeEnum,
  TransactionDetailType,
  TransactionType,
} from '@interfaces'
import {
  formatCurrency,
  formatDate,
  formatMoney,
  getFormattedShares,
} from '@utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { cleanPdf, fetchDocument } from '../transactionPDF/thunk'
import { RootState } from 'store'
import { t } from 'i18next'
import { CloseIcon, InfoIcon, ReceiptIcon } from '@assets/svg'
import { isNil } from 'lodash'
export interface TransactionsSection {
  title?: string
  rows: SummaryRowProps[]
}

export interface FormattedValues {
  headerText: string
  isDividend: boolean
  formattedDate: string
  formattedAmount: string
  title: string
  subtitle: string
  textDetail: string
}

export function getFormattedStatus(
  status: OrderTransactionChildStatusEnum,
): string {
  switch (status) {
    case OrderTransactionChildStatusEnum.cancelled:
      return t('transactions.cancelled')
    case OrderTransactionChildStatusEnum.completed:
      return t('transactions.completed')
    case OrderTransactionChildStatusEnum.pending:
      return t('transactions.pending')
    case OrderTransactionChildStatusEnum.partiallyCompleted:
      return t('transactions.partiallyCompleted')
    case OrderTransactionChildStatusEnum.error:
      return t('transactions.error')
    default:
      return status
  }
}

export function useCancelButton(
  transaction: BuySellTransaction,
  handleOnClick: () => void,
  isCancellable: boolean,
): JSX.Element {
  return transaction?.status === OrderTransactionStatusEnum.pending ? (
    <Button
      text={t('common.cancel')}
      buttonType='ghost--negative'
      textPreset='paragraph2'
      disabled={!isCancellable}
      onClick={() => handleOnClick()}
      autoWidth={true}
      right={<CloseIcon className='negative' />}
    />
  ) : null
}

export function getOrderStatusRow(
  transaction: BuySellTransaction,
): SummaryRowProps {
  const isExpired =
    transaction?.status === OrderTransactionStatusEnum.cancelled &&
    transaction?.reason === OrderCancellationReason.expired

  const status = isExpired
    ? transaction?.reasonText
    : transaction?.childStatusText || transaction?.statusText

  return {
    label: t('transactions.status'),
    value: status,
  }
}

export function getStatusRow(
  transaction: TransactionDetailType,
): SummaryRowProps {
  const status =
    (transaction as BuySellTransaction)?.statusText ||
    getFormattedStatus(
      transaction?.status as unknown as OrderTransactionChildStatusEnum,
    )

  return {
    label: t('transactions.status'),
    value: status,
  }
}

export function getDefaultSection(
  transaction: TransactionDetailType,
): SummaryCardProps[] {
  const defaultRows = [getStatusRow(transaction)]

  return [
    {
      id: 'defaultSection',
      data: defaultRows.map((r, i) => ({
        ...r,
        showDivider: i !== defaultRows.length - 1,
      })),
    },
  ]
}

export const getNumberOfSharesRow = (
  transaction: BuySellTransaction,
): SummaryRowProps => {
  const { type, shares } = transaction
  const sharesSign = type === TransactionType.buy ? '+' : '-'
  const formattedShares = `${sharesSign}${getFormattedShares(
    shares?.executed,
    shares?.requested,
  )}`

  return {
    label: t('transactions.amountOfShares'),
    value: formattedShares,
  }
}

export const getFormattedFxRate = (transaction: BuySellTransaction): string => {
  return `${
    formatMoney(
      transaction?.exchangeRateAverage,
      transaction?.totalCost?.currency,
      4,
    ) as string
  } = ${formatMoney(1, transaction?.assetCurrency) as string}`
}

export const getTotalLabel = (transaction: BuySellTransaction): string => {
  const isBuy = transaction?.type === TransactionType.buy
  const isPending = transaction?.status === OrderTransactionStatusEnum.pending

  const isCancelled =
    transaction?.status === OrderTransactionStatusEnum.cancelled

  if (isPending) {
    return isBuy
      ? t('buySellFlow.totalOnHold')
      : t('buySellFlow.estimatedEarnings')
  }

  if (isCancelled) {
    return t('buySellFlow.releasedFromHold')
  }

  return isBuy
    ? t('buySellFlow.summary.totalCost')
    : t('buySellFlow.totalEarned')
}

export const getTotalLabelExplanation = (
  transaction: BuySellTransaction,
): string => {
  const isBuy = transaction?.type === TransactionType.buy
  const isPending = transaction?.status === OrderTransactionStatusEnum.pending

  if (isPending) {
    return isBuy
      ? t('buySellFlow.summary.totalToWithholdExplanation')
      : t('buySellFlow.summary.estimatedEarningsExplanation')
  }
}

export function getFormattedGrossAmount(
  transaction: BuySellTransaction,
): string {
  const { grossAmount } = transaction

  return formatMoney(grossAmount?.amount, grossAmount?.currency)
}

export function getFormattedNetAmount(transaction: BuySellTransaction): string {
  const { netAmount, netAmountInPortfoliosCurrency } = transaction

  return `${formatMoney(
    netAmount?.amount,
    netAmount?.currency,
  )} = ${formatMoney(
    netAmountInPortfoliosCurrency?.amount,
    netAmountInPortfoliosCurrency?.currency,
  )}`
}

export const getFeeRate = (commission: {
  amount: Money
  rate: number
}): string => {
  return !isNil(commission?.rate)
    ? `(${(commission?.rate * 100).toFixed(2)}%)`
    : ''
}

export function getOrderSections(
  transaction: BuySellTransaction,
  showInfoModal: (title: string, content: string) => void,
  showTaxesModal: () => void,
): SummaryCardProps[] {
  const {
    date,
    totalCost,
    estimatedPrice,
    executedPrice,
    status,
    expirationDate,
    commissions,
    taxes,
    lockedCash,
  } = transaction

  const executedPriceRow = {
    label: t('transactions.executedPrice'),
    value: formatCurrency(executedPrice),
  }

  const limitPriceRow = {
    label: t('transactions.limitPrice'),
    value: formatCurrency(estimatedPrice),
  }

  const bufferTranslationOption = {
    percent: transaction?.contingenciesBuffer * 100,
  }

  const isBuy = transaction?.type === TransactionType.buy

  const isLimit = transaction?.orderMethod === OrderTypeEnum?.limit

  const isCompleted = status === OrderTransactionStatusEnum.completed
  const isCancelled = status === OrderTransactionStatusEnum.cancelled
  const isPending = status === OrderTransactionStatusEnum.pending

  const dateCreatedRow = {
    label: t('transactions.dateCreated'),
    value: formatDate(date),
  }

  const expirationDateRow = {
    label: t('transactions.expirationDate'),
    value: formatDate(expirationDate),
  }

  const buyCommissionsRows = isBuy
    ? [
        {
          label: `${t('common.commissions.custodyFees')} ${getFeeRate(
            commissions?.custodyFees,
          )}`,
          value: formatCurrency(commissions?.custodyFees?.amount),
        },
        {
          label: `${t(
            'common.commissions.financialInstrumentCost',
          )} ${getFeeRate(commissions?.financialInstrumentFees)}`,
          value: formatCurrency(commissions?.financialInstrumentFees?.amount),
        },
        {
          label: `${t(
            'common.commissions.paymentsReceivedFromThirdParties',
          )} ${getFeeRate(commissions?.thirdPartyPayments)}`,
          value: formatCurrency(commissions?.thirdPartyPayments?.amount),
        },
      ]
    : []

  const formattedEstimatedValue = getFormattedNetAmount(transaction)

  const estimatedNetAmountRow = {
    label: !isCompleted
      ? t('transactions.estimatedNetAmount')
      : t('transactions.netAmount'),
    value: formattedEstimatedValue,
  }

  const requiredNetAmountRow = {
    label: t('buySellFlow.requiredNetAmount'),
    value: formatCurrency(transaction?.requiredNetAmount),
  }

  const totalCommissionsRow = {
    label: `${t('common.commissions.totalCommissions')}`,
    value: formatCurrency(commissions?.totalCommission),
  }

  const exchangeFeeRow = {
    label: `${t('common.commissions.exchangeFee')} ${getFeeRate(
      commissions?.forexFees,
    )}`,
    value: formatCurrency(commissions?.forexFees?.amount),
  }

  const executionFeeRow = {
    label: t('common.commissions.executionFees'),
    value: formatCurrency(transaction?.commissions?.executionFees?.amount),
  }

  const fxRateRow = {
    label: t('transactions.fxRate'),
    value: getFormattedFxRate(transaction),
  }

  const taxesRow = {
    label: t('buySellFlow.summary.taxes'),
    value: formatCurrency(taxes),
    labelChildren: (
      <InfoIcon className='ml-1 cursor-pointer' onClick={showTaxesModal} />
    ),
  }

  const orderDetailsTitle = isLimit
    ? t('transactions.limitOrderDetailedInfo')
    : t('transactions.marketOrderDetailedInfo')

  const estimatedGrossAmount = {
    label: t('transactions.estimatedGrossAmount'),
    value: getFormattedGrossAmount(transaction),
  }

  const bufferIncludedRow = {
    label: t('transactions.bufferIncluded', bufferTranslationOption),
    labelChildren: (
      <InfoIcon
        className='ml-1 cursor-pointer'
        onClick={() =>
          showInfoModal(
            t('transactions.bufferIncluded', bufferTranslationOption),
            t('transactions.bufferExplanation', bufferTranslationOption),
          )
        }
      />
    ),
    value: formatCurrency(transaction?.contingenciesBufferAmount),
  }

  const statusSection = {
    id: 'orderStatusSection',
    overTitle: t('transactions.orderStatus'),
    data: [getOrderStatusRow(transaction), dateCreatedRow],
  }

  const firstTotalValue = formatCurrency(
    isBuy && !isCompleted && !isNil(lockedCash) ? lockedCash : totalCost,
  )

  const totalLabel = getTotalLabel(transaction)
  const totalLabelExplanation = getTotalLabelExplanation(transaction)

  const totalCostRow = {
    labelChildren: (
      <div className='totalRow w-100'>
        <div className='justify-space-between d-flex'>
          <div className='d-flex align-center'>
            <p className='headline4'>{totalLabel}</p>
            {totalLabelExplanation ? (
              <InfoIcon
                className='ml-1 cursor-pointer'
                onClick={() => showInfoModal(totalLabel, totalLabelExplanation)}
              />
            ) : null}
          </div>
          <p className='headline4'>{firstTotalValue}</p>
        </div>
        {isPending && isBuy ? (
          <div className='mt-1 justify-space-between d-flex'>
            <div className='d-flex align-center'>
              <p className='paragraph2'>{t('transactions.estimatedTotal')}</p>
              <InfoIcon
                className='ml-1 cursor-pointer'
                onClick={() =>
                  showInfoModal(
                    t('transactions.estimatedTotal'),
                    t('buySellFlow.summary.estimatedTotalExplanation'),
                  )
                }
              />
            </div>
            <p className='paragraph2'>{formatCurrency(totalCost)}</p>
          </div>
        ) : null}
      </div>
    ),
  }

  if ((isCancelled || isPending) && isLimit) {
    return [
      statusSection,
      {
        id: 'orderDetailsSection',
        overTitle: orderDetailsTitle,
        data: [
          limitPriceRow,
          expirationDateRow,
          getNumberOfSharesRow(transaction),
          estimatedGrossAmount,
          taxesRow,
          fxRateRow,
          estimatedNetAmountRow,
          ...(isBuy ? [bufferIncludedRow, requiredNetAmountRow] : []),
        ],
      },
      {
        id: 'totalAndCommissions',
        overTitle: t('transactions.executionCommissions'),
        data: [
          executionFeeRow,
          exchangeFeeRow,
          ...buyCommissionsRows,
          totalCommissionsRow,
          totalCostRow,
        ],
      },
    ]
  }
  return [
    statusSection,
    {
      id: 'orderDetailsSection',
      overTitle: orderDetailsTitle,
      data: [
        executedPriceRow,
        getNumberOfSharesRow(transaction),
        estimatedGrossAmount,
        taxesRow,
        fxRateRow,
        estimatedNetAmountRow,
      ],
    },
    {
      id: 'totalAndCommissions',
      overTitle: t('transactions.executionCommissions'),
      data: [
        executionFeeRow,
        exchangeFeeRow,
        ...buyCommissionsRows,
        totalCommissionsRow,
        totalCostRow,
      ],
    },
  ]
}

export function getDepositOrWithdrawalRows(
  transaction: DepositWithdrawalTransaction,
): SummaryCardProps[] {
  const { type, amount, fees } = transaction

  return [
    {
      id: 'depositOrWithdrawalRows',
      data: [
        getStatusRow(transaction),
        {
          label:
            type === TransactionType.deposit
              ? t('transactions.amountDeposited')
              : t('transactions.amountWithdrawn'),
          value: formatMoney(amount?.amount, amount?.currency),
        },
        {
          label: t('buySellFlow.summary.fees'),
          value: formatMoney(fees?.amount, fees?.currency),
        },
      ],
    },
  ]
}

export function useSectionRows(
  transaction: TransactionDetailType,
  showInfoModal: (title: string, content: string) => void,
  showTaxesModal: () => void,
): SummaryCardProps[] {
  // const { taxesUrl } = useSelector((state: RootState) => state.commonUrls)

  if (!isEmpty(transaction)) {
    const { type } = transaction

    // Buy or Sell orders
    if (type === TransactionType.buy || type === TransactionType.sell) {
      return getOrderSections(
        transaction as BuySellTransaction,
        showInfoModal,
        showTaxesModal,
      )
    }

    // Deposits or Withdrawal
    if (
      type === TransactionType.deposit ||
      type === TransactionType.withdrawal
    ) {
      return getDepositOrWithdrawalRows(
        transaction as DepositWithdrawalTransaction,
      )
    }

    return getDefaultSection(transaction)
  }
  return null
}

export function useFormattedValues(
  transactionType: TransactionType,
  transactionDetail?: TransactionDetailType,
  financialEvent?: string,
): FormattedValues {
  const orderType = (transactionDetail as BuySellTransaction)?.orderMethod
  const headerText = useMemo(() => {
    switch (transactionType) {
      case TransactionType.dividendCash:
        return t('transactions.dividend')
      case TransactionType.dividendShares:
        return financialEvent || t('transactions.financialEvent')
      case TransactionType.deposit:
        return t('transactions.deposit')
      case TransactionType.withdrawal:
        return t('transactions.withdrawal')
      case TransactionType.buy:
        return orderType === OrderTypeEnum.limit
          ? t('transactions.buyLimitOrder')
          : t('transactions.buy')
      case TransactionType.sell:
        return orderType === OrderTypeEnum.limit
          ? t('transactions.sellLimitOrder')
          : t('transactions.sell')
      default:
        return ''
    }
  }, [orderType, transactionType])

  const isDividend = useMemo(
    () =>
      transactionType === TransactionType.dividendCash ||
      transactionType === TransactionType.dividendShares,
    [transactionType],
  )

  const formattedDate = useMemo(() => {
    let unformattedDate = new Date()
    if (transactionDetail?.date) {
      unformattedDate = new Date(transactionDetail?.date)
    }
    return isDividend
      ? unformattedDate.toLocaleDateString()
      : unformattedDate.toLocaleString()
  }, [isDividend, transactionDetail?.date])

  const formattedAmount: string = useMemo(() => {
    if (!transactionDetail) {
      return null
    }

    if (
      transactionType === TransactionType.buy ||
      transactionType === TransactionType.sell
    ) {
      const buySellTransaction = transactionDetail as BuySellTransaction

      const isBuy = buySellTransaction?.type === TransactionType.buy

      const isNotCompleted =
        buySellTransaction?.status !== OrderTransactionStatusEnum.completed

      const total =
        isBuy && isNotCompleted && buySellTransaction?.lockedCash
          ? buySellTransaction?.lockedCash
          : buySellTransaction?.totalCost

      return formatCurrency(total)
    }

    return formatCurrency(transactionDetail?.amount)
  }, [transactionDetail, transactionType])

  // Shares only apply to orders and divident shares
  const shares: number = useMemo(() => {
    if (
      transactionType === TransactionType.buy ||
      transactionType === TransactionType.sell
    ) {
      return (transactionDetail as BuySellTransaction)?.shares?.requested
    }
    if (transactionType === TransactionType.dividendShares) {
      return (transactionDetail as DividendTransaction)?.sharesQuantity
    }

    return null
  }, [transactionDetail, transactionType])

  const textDetail = useMemo(() => {
    if (!transactionDetail) {
      return null
    }

    if (transactionType === TransactionType.dividendShares) {
      const { sharesQuantity } = transactionDetail as DividendTransaction
      return t(
        sharesQuantity === 1
          ? t('transactions.share_singular')
          : t('transactions.share_plural'),
        { sharesQuantity },
      )
    }
    return formattedAmount || '0'
  }, [transactionDetail, transactionType, formattedAmount])

  const title = useMemo(() => {
    if (!transactionDetail) {
      return null
    }

    if (textDetail?.includes('+') || textDetail?.includes('-')) {
      return textDetail
    }

    if (transactionType === TransactionType.buy) {
      return `-${textDetail}`
    }
    if (transactionType === TransactionType.sell) {
      return `+${textDetail}`
    }
    if (transactionType === TransactionType.dividendShares) {
      return `+${textDetail}`
    }
    return textDetail
  }, [transactionDetail, transactionType, textDetail])

  const subtitle = useMemo(() => {
    const displayedShares = shares ? ` ${shares} ` : ''
    return transactionDetail?.ticker
      ? `${headerText}${displayedShares}${transactionDetail?.ticker}`
      : `${headerText}${displayedShares}`
  }, [headerText, transactionDetail?.ticker, shares])

  return {
    headerText,
    isDividend,
    title,
    subtitle,
    formattedDate,
    formattedAmount,
    textDetail,
  }
}

export function useTransactionDocButton(
  transactionDetail: TransactionDetailType,
  financialEvent: string,
): JSX.Element {
  const dispatch = useDispatch()
  const transactionPdf = useSelector((state: RootState) => state.transactionPdf)
  const pdfTo = (id: string): void => {
    dispatch(fetchDocument(id))
  }

  const base64ToArrayBuffer = (base64: string): Uint8Array => {
    const binaryString = window.atob(base64)
    const binaryLen = binaryString.length
    const bytes = new Uint8Array(binaryLen)
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i)
      bytes[i] = ascii
    }
    return bytes
  }

  if (transactionPdf.file) {
    const bytesBlob = base64ToArrayBuffer(transactionPdf.file)
    const newBlob = new Blob([bytesBlob], { type: 'application/pdf' })
    const urlBlob = URL.createObjectURL(newBlob)

    window.open(urlBlob)
    dispatch(cleanPdf())
  }

  const isDividend =
    transactionDetail?.type === TransactionType.dividendCash ||
    transactionDetail?.type === TransactionType.dividendShares
  const buttonText = isDividend
    ? financialEvent
      ? t('transactions.financialEventConfirmation')
      : t('transactions.dividendConfirmation')
    : t('transactions.operationConfirmation')

  return [
    TransactionType.dividendCash,
    TransactionType.dividendShares,
    TransactionType.buy,
    TransactionType.sell,
  ].includes(transactionDetail?.type as TransactionType) &&
    transactionDetail?.documentId ? (
    <Button
      autoWidth={true}
      text={buttonText}
      buttonType='ghost'
      textPreset='paragraph2'
      loading={transactionPdf.loading}
      onClick={() => pdfTo(transactionDetail?.documentId)}
      right={<ReceiptIcon />}
    />
  ) : null
}
