import { useTranslation } from '@hooks'
import { Button, CustomModal, Spacer, Text } from '@components'

export const BuyInfoModal = ({
  close,
  title,
  description,
}: {
  close: () => void
  title: string
  description: string
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <CustomModal
      show={true}
      theme='lighter'
      size='small'
      showBackgrop={false}
      dismissable
      onClose={close}
    >
      <CustomModal.Header text={title} />
      <CustomModal.Content>
        <Spacer preset='tiny' />
        <Text preset='paragraph2' text={description} align='center' />
        <Spacer preset='tiny' />
      </CustomModal.Content>
      <CustomModal.Footer>
        <Button text={t('common.ok')} onClick={close} buttonType='primary' />
      </CustomModal.Footer>
    </CustomModal>
  )
}
