import { Transaction, TransactionGroup, TransactionType } from '@interfaces'
import { useTranslation } from '@hooks'
import { GroupedTransactions } from '@screens'
import { Show, Spacer, Text } from '@components'
import { PendingEmptyIllustration, SearchEmpty } from '@assets/illustrations'

export const PendingTab = ({
  groups,
  onTransactionClick,
  thereAreActiveFilters,
  animated,
}: {
  groups: TransactionGroup<Transaction>[]
  thereAreActiveFilters: boolean
  animated: boolean
  onTransactionClick: (
    id: string,
    type: TransactionType,
    financialEvent: string,
  ) => void
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Show
      when={groups?.length > 0}
      fallback={
        thereAreActiveFilters ? (
          <div className='transactions__empty-block d-flex d-flex-col justify-center align-center'>
            <SearchEmpty />
            <Spacer preset='small' />
            <Text preset='headline3' text={t('common.weCouldntFindResults')} />
            <Spacer preset='tiny' />
            <Text preset='paragraph2' text={t('search.tryAgainFilters')} />
          </div>
        ) : (
          <div className='transactions__empty-block'>
            <PendingEmptyIllustration />
            <Spacer preset='small' />
            <Text
              preset='paragraph5'
              text={t('transactions.pending.emptyTitle')}
            />
            <Spacer preset='tiny' />
            <Text
              preset='paragraph2'
              text={t('transactions.pending.emptyDescription')}
            />
          </div>
        )
      }
    >
      <GroupedTransactions
        withTitle={false}
        groups={groups}
        onTransactionClick={onTransactionClick}
        animated={animated}
      />
    </Show>
  )
}
