/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import './transactionDetails.style.scss'
import {
  GoBack,
  BigSpinner,
  SummaryCard,
  StickyElementsWrapper,
  SummaryCardProps,
  Show,
  CustomModal,
  Spacer,
  Text,
  Button,
} from '@components'
import { useNavigate, useTranslation } from '@hooks'
import { useDispatch, useSelector } from 'react-redux'
import {
  CommonUrlsState,
  fetchTaxesUrl,
  fetchUserBalances,
  RootState,
} from '@store'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  clearTransactionDetail,
  fetchOrderDetail,
  fetchTransactionDetail,
  setCancelOrder,
} from './thunk'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  TransactionType,
  ReducerState,
  BuySellTransaction,
  OrderTypeEnum,
  TransactionsTab,
  OrderTransactionStatusEnum,
  NotCancellableReason,
} from '@interfaces'
import { TransactionDetailState } from './reducers'
import { Cashout, TopUp } from '@assets/svg'
import {
  useCancelButton,
  useFormattedValues,
  useSectionRows,
  useTransactionDocButton,
} from './transactionDetails.hooks'
import { setTransactionsTab } from '../thunk'
import { ReactComponent as ProhibitedIcon } from '@assets/svg/profile/prohibited.svg'
import { trackingService } from '@services'
import { LimitOrderEvents } from '@utils/eventTracker/eventKeys'
import { CancelOrderModal } from '../../../shared/modals/cancelOrderModal/cancelOrderModal'

export default function TransactionDetails(): JSX.Element {
  const { navigate } = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { transactionId, transactionType } = useParams()
  const [searchParams] = useSearchParams()
  const financialEvent = searchParams.get('financialEvent')
  const {
    loading,
    transactionDetail,
    loadingCancelOrder,
    cancelledOrder,
  }: ReducerState & TransactionDetailState = useSelector(
    (state: RootState) => state.transactionDetail,
  )

  const { taxesUrl }: CommonUrlsState = useSelector(
    (state: RootState) => state.commonUrls,
  )

  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showTaxesModal, setShowTaxesModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [modalTitle, setModalTitle] = useState<string>(null)
  const [modalContent, setModalContent] = useState<string>(null)

  const setInfoModalContent = (title: string, content: string): void => {
    setModalTitle(title)
    setModalContent(content)
    setShowInfoModal(true)
  }

  const isOrder = useMemo(() => {
    return (
      transactionDetail?.type === TransactionType.buy ||
      transactionDetail?.type === TransactionType.sell
    )
  }, [transactionDetail?.type])

  const isCancelledOrder = useMemo(() => {
    return (
      isOrder &&
      transactionDetail?.status === OrderTransactionStatusEnum.cancelled
    )
  }, [isOrder, transactionDetail?.status])

  const isCancellable: boolean = (transactionDetail as BuySellTransaction)
    ?.isCancellable

  const notCancellableReason: NotCancellableReason = (
    transactionDetail as BuySellTransaction
  )?.notCancellableReason

  const getTransactionDetail = useCallback(() => {
    if (
      transactionType != TransactionType.buy &&
      transactionType != TransactionType.sell
    ) {
      dispatch(
        fetchTransactionDetail({
          id: transactionId,
          type: transactionType as TransactionType,
        }),
      )
    } else {
      dispatch(fetchOrderDetail({ id: transactionId }))
    }
  }, [dispatch, transactionId, transactionType])

  const {
    headerText,
    isDividend,
    title: amount,
    subtitle: sharesAmount,
    textDetail,
    formattedDate,
  } = useFormattedValues(
    transactionType as TransactionType,
    transactionDetail,
    financialEvent,
  )

  const sections = useSectionRows(transactionDetail, setInfoModalContent, () =>
    setShowTaxesModal(true),
  )

  const renderSection = (data: SummaryCardProps, idx: string): JSX.Element => {
    return <SummaryCard {...data} key={idx} className='mb-3' />
  }

  useEffect(() => {
    dispatch(fetchTaxesUrl())
    getTransactionDetail()
  }, [dispatch, getTransactionDetail, transactionId, transactionType])

  useEffect(() => {
    if (cancelledOrder) {
      dispatch(fetchUserBalances())
      dispatch(clearTransactionDetail())
      navigate(-1)
    }
  }, [cancelledOrder, dispatch, navigate])

  useEffect(() => {
    if (
      transactionType === TransactionType.buy ||
      (transactionType === TransactionType.sell &&
        (transactionDetail as BuySellTransaction)?.orderMethod ===
          OrderTypeEnum.limit)
    ) {
      trackingService.trackEvent({
        event: LimitOrderEvents.detailOpened,
        props: {
          ticker: transactionDetail?.ticker,
        },
      })
    }
    // We only want this to be executed one time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const docButton = useTransactionDocButton(transactionDetail, financialEvent)

  const cancelBtn = useCancelButton(
    transactionDetail as BuySellTransaction,
    () => setShowCancelModal(true),
    isCancellable,
  )

  const handleCancelOrder = (): void => {
    setShowCancelModal(false)
    trackingService.trackEvent({
      event: LimitOrderEvents.cancelMarketClosed,
      props: {
        ticker: transactionDetail?.ticker,
        estimatedPrice: (transactionDetail as BuySellTransaction)?.totalCost
          .amount,
        creationDate: (transactionDetail as BuySellTransaction)?.creationDate,
        expirationDate: (transactionDetail as BuySellTransaction)
          ?.expirationDate,
      },
    })
    dispatch(setCancelOrder({ id: transactionId }))
  }

  const getTransactionsTab = (status: string): TransactionsTab => {
    switch (status) {
      case OrderTransactionStatusEnum.cancelled:
        return TransactionsTab.closed
      case OrderTransactionStatusEnum.completed:
        return TransactionsTab.executed
      case OrderTransactionStatusEnum.placed:
      case OrderTransactionStatusEnum.processing:
      case OrderTransactionStatusEnum.pending:
        return TransactionsTab.pending
      default:
        return TransactionsTab.executed
    }
  }

  const handleGoBack = (): void => {
    const tab = getTransactionsTab(transactionDetail?.status as string)
    dispatch(setTransactionsTab({ tab }))
    dispatch(clearTransactionDetail())

    navigate(`/transactions/${tab.toLowerCase()}`)
  }

  const handleAssetNavigation = (): void =>
    navigate(`/company/${(transactionDetail as BuySellTransaction)?.asset?.id}`)

  return (
    <div>
      <StickyElementsWrapper startPoint={56} zIndex={'3'}>
        <div className='transactionDetails__header' data-sticky>
          <GoBack goBack={handleGoBack} />
        </div>
        <div className='transactionDetails__container h-100 --fade-in-animation align-center position-relative'>
          {(loading ||
            loadingCancelOrder ||
            cancelledOrder ||
            !transactionDetail) && (
            <>
              {loadingCancelOrder ? (
                <div className='home__loader home__loader--inner transactionDetails__loader'>
                  <BigSpinner>
                    <>
                      <p className='text-center mt-3 mb-1 headline4'>
                        {t('transactions.cancellingOrder')}
                      </p>
                      <p className='text-center paragraph2 secondary--text'>
                        {t('common.takeAMin')}
                      </p>
                    </>
                  </BigSpinner>
                </div>
              ) : (
                <div className='home__loader home__loader--inner'>
                  <BigSpinner />
                </div>
              )}
            </>
          )}
          {!cancelledOrder && !loadingCancelOrder && !loading && (
            <div className='transactionDetails__content --fade-in-animation'>
              <div className='transactionDetails__topContainer'>
                <div className='transactionDetails__titleSection'>
                  <p className='headline1'>{headerText}</p>
                  <p
                    className={`transactionDetails__amount headline1 ${
                      isCancelledOrder
                        ? 'transactionDetails__amount--crossed'
                        : ''
                    }`}
                  >
                    {amount}
                  </p>
                  <p className='paragraph2 tertiary--text'>
                    {sharesAmount} · {formattedDate}
                  </p>
                </div>
                {isOrder ? (
                  <div
                    className='cursor-pointer'
                    onClick={handleAssetNavigation}
                    aria-hidden
                  >
                    <img
                      className='transactionDetails__icon icon--massivePlus'
                      src={transactionDetail?.logo}
                      alt={headerText}
                      title={headerText}
                    />
                  </div>
                ) : (
                  <>
                    {transactionType === TransactionType.withdrawal && (
                      <Cashout />
                    )}
                    {transactionType === TransactionType.deposit && <TopUp />}
                  </>
                )}
              </div>
              {docButton}
              {cancelBtn}
              <Spacer preset='smaller' />
              {!isCancellable &&
                notCancellableReason === NotCancellableReason.marketClosed && (
                  <div className='d-flex mb-2'>
                    <ProhibitedIcon className='secondary--text' />
                    <div className='transactionDetails__marketClosed'>
                      <p className='paragraph2 transactionDetails__marketClosed--title'>
                        {t('transactions.marketClosedTitle')}
                      </p>
                      <p className='paragraph2 secondary--text'>
                        {t('transactions.limitOrdersMarketClosed')}
                      </p>
                    </div>
                  </div>
                )}
              {sections?.length > 0 &&
                !loading &&
                sections?.map((data: SummaryCardProps, idx: number) => {
                  return renderSection(
                    data,
                    `${transactionId}-${idx as unknown as string}`,
                  )
                })}
              {isDividend && (
                <p className='transactionDetails__dividendText'>
                  {t(
                    transactionType === TransactionType.dividendShares
                      ? 'transactions.dividendShare'
                      : 'transactions.dividendMoney',
                    {
                      titles: textDetail,
                      company: transactionDetail?.ticker,
                    },
                  )}
                </p>
              )}
            </div>
          )}
        </div>
      </StickyElementsWrapper>
      <Show when={showCancelModal}>
        <CancelOrderModal
          close={() => setShowCancelModal(false)}
          handleCancelOrder={handleCancelOrder}
        />
      </Show>
      <Show when={showInfoModal}>
        <CustomModal show theme='lighter' size='small'>
          <CustomModal.Header text={modalTitle} />
          <CustomModal.Content>
            <Spacer preset='tiny' />
            <Text preset='paragraph1' text={modalContent} align='center' />
            <Spacer preset='tiny' />
          </CustomModal.Content>
          <CustomModal.Footer>
            <Button
              text={t('common.ok')}
              onClick={() => setShowInfoModal(false)}
              buttonType='primary'
            />
          </CustomModal.Footer>
        </CustomModal>
      </Show>
      <Show when={showTaxesModal}>
        <CustomModal show theme='lighter' size='small'>
          <CustomModal.Header text={t('buySellFlow.summary.taxesInfo.title')} />
          <CustomModal.Content>
            <div className='d-flex d-flex-col align-center w-100'>
              <object
                data={taxesUrl}
                height='300'
                width='100%'
                title='taxes'
              ></object>
            </div>
          </CustomModal.Content>
          <CustomModal.Footer>
            <Button
              text={t('common.ok')}
              onClick={() => setShowTaxesModal(false)}
              buttonType='primary'
            />
          </CustomModal.Footer>
        </CustomModal>
      </Show>
    </div>
  )
}
